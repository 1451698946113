@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body html {
  font-family: "Roboto", sans-serif;
}
::-webkit-scrollbar {
  width: 0.7rem;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background: rgb(175, 172, 167);
  border: 3px solid transparent;
  background-clip: content-box;
  border-radius: 15px;
  /* height: 10%; */
}
