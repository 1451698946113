.App {
  text-align: center;
  background-color: green;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body html {
  font-family: "Roboto", sans-serif;
}

.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #a0aec0 !important;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 7px !important; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 25px !important; /* Height */
}

@media (min-width: 768px) {
  .shrinkable-table {
    flex: 1;
    width: 100%;
  }
}

.calendarWrap {
  display: inline-block;
  position: relative;
  width: 100%;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
  width: 90%;
}
.customTable tbody tr:nth-of-type(odd) {
  background-color: #eef8ff;
}
